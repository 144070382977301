import { Box, Card, Grid, Stack, Tab, Tabs, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/warehousesStock";
import Itens from "./itens";
import { initialValues, yupSchema } from "./yupSchema";

// forms alternativos
import AdicionarFuncionario from "layouts/funcionarios";
import AdicionarProduto from "layouts/recebidos";
import { format } from "utils/formatDate";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#81BF52",
    },
    "& .MuiTab-root": {
      color: "#425d73 !important",
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#81bf52ab",
      color: "#FFFFFF !important",
    },
  },
});

export default function DetalharEstoqueTransitorio({
  setShowDetailState,
  fetchData,
  warehouseId,
  currentIdAction,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [showAddFuncionario, setShowAddFuncionario] = useState(false);
  const [childData, setChildData] = useState({});
  const [showAddProduto, setShowAddProduto] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const classes = useStyles();

  const selectParent = (parent, value) => {
    formik.setFieldValue(parent, value);
  };

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);
      for (var key in formik.values) {
        if (key.includes("data"))
          formik.setFieldValue(
            key,
            fields[key] !== undefined && fields[key] !== null
              ? format(new Date(fields[key]), "yyyy-MM-dd")
              : ""
          );
        else formik.setFieldValue(key, fields[key]);
      }

      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
  });

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        if (showAddFuncionario) setShowAddFuncionario(false);

        if (!showAddFuncionario) {
          setShowDetailState(false);
          fetchData();
        }

        setSuccess(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, [showAddFuncionario]);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Estoque Transitório
                </MDTypography>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    Imprimir
                  </MDButton>
                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => {
                      fetchData();
                      setSuccess(null);
                      setShowDetailState(false);
                    }}
                  >
                    Voltar
                  </MDButton>
                </Stack>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2} id="print-area">
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Nome do Depósito"
                    value={formik.values.warehouse?.descricao}
                    disabled
                    InputLabelProps={{ readOnly: true, shrink: true }}
                  />

                  <TextField
                    id="responsavel"
                    name="responsavel"
                    label="Responsável pelo Depósito"
                    value={formik.values.responsavel}
                    disabled
                    InputLabelProps={{ readOnly: true }}
                  />
                  <TextField
                    id="usuario"
                    name="usuario"
                    label="Usuário criador da solicitação"
                    value={formik.values.usuario}
                    disabled
                    InputLabelProps={{ readOnly: true }}
                  />
                  <TextField
                    id="dataDeRetirada"
                    name="dataDeRetirada"
                    label="Data de Retirada"
                    type="date"
                    value={formik.values.dataDeRetirada}
                    disabled
                    InputLabelProps={{ readOnly: true, shrink: true }}
                  />
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ width: "100%", gridColumn: "span 2" }}
                    mb={2}
                  >
                    <Tabs
                      orientation={"horizontal"}
                      value={0}
                      className={classes.tabs}
                    >
                      <Tab label="Produtos a serem cautelados" />
                    </Tabs>
                    <Itens
                      {...{
                        formik,
                        loading,
                      }}
                      setCurrentItem={setCurrentItem}
                      currentItem={currentItem}
                      isDetalhar={true}
                    />
                  </Grid>
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* forms alternativos */}
        {showAddProduto && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarProduto
              setShowAddProduto={setShowAddProduto}
              selectParent={setCurrentItem}
              disableForm={true}
              fromParent={true}
              grupoProduto="ALMOXARIFADO"
              filterByWarehouse={warehouseId.id}
            />
          </MDBox>
        )}
        {showAddFuncionario && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarFuncionario
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, responsavel: value })
                );
                selectParent("responsavel", value?.name);
              }}
              setShowAddFuncionario={setShowAddFuncionario}
            />
          </MDBox>
        )}
      </MDBox>
    </>
  );
}

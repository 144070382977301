import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Icon,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import authService from "services/auth-service";
import Service from "services/warehousesStock";
import { initialValues, yupSchema } from "./yupSchema";
import Itens from "./itens";
import { makeStyles } from "@mui/styles";

// forms alternativos
import AdicionarFuncionario from "layouts/funcionarios";
import AdicionarProduto from "layouts/recebidos";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#81BF52",
    },
    "& .MuiTab-root": {
      color: "#425d73 !important",
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#81bf52ab",
      color: "#FFFFFF !important",
    },
  },
});

export default function AdicionarDeposito({
  setShowAddState,
  fetchData,
  warehouseId,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [showAddFuncionario, setShowAddFuncionario] = useState(false);
  const [childData, setChildData] = useState({});
  const [showAddProduto, setShowAddProduto] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const classes = useStyles();

  const selectParent = (parent, value) => {
    formik.setFieldValue(parent, value);
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      dataDeRetirada: new Date().toISOString().split("T")[0],
      warehouse: warehouseId?.id,
    },
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      values.produtos.forEach((produto) => {
        if (produto.qtdRetirada > produto.qtdRecebida) {
          setSuccess(false);
          setLoading(false);
          setErrorMessage(
            "Quantidade retirada não pode ser maior que a quantidade em estoque"
          );
          throw new Error(
            "Quantidade retirada maior que a quantidade recebida"
          );
        }
      });

      try {
        await Service.create(values);
        setSuccess(true);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }

      setAlertKey(alertKey + 1);
      setLoading(false);
    },
  });

  const getUserData = async () => {
    const response = await authService.getProfile();

    formik.setFieldValue("usuario", response.data.attributes.name);
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        if (showAddFuncionario) setShowAddFuncionario(false);

        if (!showAddFuncionario) {
          setShowAddState(false);
          fetchData();
        }

        setSuccess(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, [showAddFuncionario]);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Cadastrar Estoque Transitório
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Nome do Depósito"
                    value={warehouseId?.nome}
                    disabled
                    InputLabelProps={{ readOnly: true }}
                  />
                  <Grid item width="100%">
                    <Alert
                      icon={false}
                      severity="success"
                      action={
                        <MDButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          iconOnly
                          onClick={() => setShowAddFuncionario(true)}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </MDButton>
                      }
                      sx={{
                        border: formik.errors.responsavel
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <b>Responsável pelo Depósito: </b>
                      {childData.responsavel?.name}
                    </Alert>
                  </Grid>
                  <TextField
                    id="usuario"
                    name="usuario"
                    label="Usuário criador da solicitação"
                    value={formik.values.usuario}
                    onChange={formik.handleChange}
                    disabled
                    InputLabelProps={{ readOnly: true }}
                  />
                  <TextField
                    id="dataDeRetirada"
                    name="dataDeRetirada"
                    label="Data de Retirada"
                    type="date"
                    value={formik.values.dataDeRetirada}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dataDeRetirada &&
                      Boolean(formik.errors.dataDeRetirada)
                    }
                    helperText={
                      formik.touched.dataDeRetirada &&
                      formik.errors.dataDeRetirada
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ width: "100%", gridColumn: "span 2" }}
                    mb={2}
                  >
                    <Tabs
                      orientation={"horizontal"}
                      value={0}
                      className={classes.tabs}
                    >
                      <Tab label="Produtos a serem cautelados" />
                    </Tabs>
                    <Itens
                      {...{
                        formik,
                        loading,
                      }}
                      setShowAddProduto={setShowAddProduto}
                      setCurrentItem={setCurrentItem}
                      currentItem={currentItem}
                    />
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Estoque transitório cadastrado com sucesso!"
                          : errorMessage ||
                            "Ocorreu um erro ao cadastrar estoque transitório."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* forms alternativos */}
        {showAddProduto && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarProduto
              setShowAddProduto={setShowAddProduto}
              selectParent={setCurrentItem}
              disableForm={true}
              fromParent={true}
              grupoProduto="ALMOXARIFADO"
              filterByWarehouse={warehouseId.id}
            />
          </MDBox>
        )}
        {showAddFuncionario && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarFuncionario
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, responsavel: value })
                );
                selectParent("responsavel", value?.name);
              }}
              setShowAddFuncionario={setShowAddFuncionario}
            />
          </MDBox>
        )}
      </MDBox>
    </>
  );
}

import { Icon, TextField, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";

const QTD = ({ formik, produto }) => {
  const [qtdRetirada, setQtd] = useState(produto?.qtdRetirada || 0);

  useEffect(() => {
    const produtos = formik.values?.produtos?.map((item) => {
      if (item?._id === produto?._id) {
        return {
          ...item,
          qtdRetirada,
          valorRetirado: (item.valorTotalSolicitado / item.qtd) * qtdRetirada,
        };
      }
      return item;
    });

    formik.setFieldValue("produtos", produtos);
  }, [qtdRetirada]);

  const plus = () => {
    setQtd(Number(qtdRetirada) + 1);
  };

  const minus = () => {
    if (qtdRetirada > 0) {
      setQtd(Number(qtdRetirada) - 1);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
          margin: "0 5px",
        },
      }}
    >
      <Icon
        style={{ color: "#81BF52", cursor: "pointer" }}
        fontSize="small"
        onClick={minus}
      >
        remove
      </Icon>

      <TextField
        type="number"
        min={0}
        value={qtdRetirada}
        onChange={(e) => setQtd(Number(e.target.value))}
        onInput={(e) => {
          if (e.target.value.split(".")[1]?.length > 2) {
            e.target.value = parseFloat(e.target.value).toFixed(2);
          }
        }}
        sx={{
          mx: "5px",
        }}
      />

      <Icon
        style={{ color: "#81BF52", cursor: "pointer" }}
        fontSize="small"
        onClick={plus}
      >
        add
      </Icon>
    </div>
  );
};

export default function Itens({
  formik,
  isVenda = false,
  isDetalhar = false,
  setShowAddProduto = () => {},
  setShowAddProdutoLote = () => {},
  setCurrentItem = () => {},
  currentItem = null,
}) {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      Header: "Ordem de Compra",
      accessor: "numPedido",
      align: "left",
    },
    {
      Header: "Produto",
      accessor: "produto",
      align: "left",
    },
    {
      Header: "Lote / Série",
      accessor: "lote",
      align: "left",
    },
    {
      Header: "Unidade de Medida",
      accessor: "unidadeMedida",
      align: "left",
    },
    {
      Header: "QTD em Estoque",
      accessor: "qtdRecebida",
      align: "left",
    },
    {
      Header: "QTD Retirada",
      accessor: "qtdRetirada",
      align: "left",
    },
    { Header: isDetalhar ? "" : "Ação", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    formik.values?.produtos &&
      setRows(
        formik.values?.produtos?.map((produto) => ({
          numPedido: produto?.numPedido,
          produto: produto?.produto,
          lote: produto?.lote,
          qtdRetirada: isDetalhar ? (
            produto.qtdRetirada
          ) : (
            <QTD formik={formik} produto={produto} />
          ),
          unidadeMedida: produto?.unidadeMedida,
          qtdRecebida: produto?.qtdRecebida,
          dataVencimento: produto?.dataVencimento,
          valorRetirado: 0,
          action: (
            <div
              style={{
                justifyContent: "center",
                display: isDetalhar ? "none" : "flex",
              }}
            >
              <Tooltip title="Remover">
                <MDButton
                  variant="gradient"
                  color="error"
                  fullWidth
                  type="submit"
                  onClick={() => handleDelete(produto)}
                  iconOnly
                >
                  <Icon fontSize="small">delete_forever</Icon>
                </MDButton>
              </Tooltip>
            </div>
          ),
        }))
      );
  }, [formik.values]);

  const handleDelete = (produto) => {
    const produtos = formik.values?.produtos?.filter(
      (item) => item?._id !== produto?._id
    );

    formik.setFieldValue("produtos", produtos);
  };

  const handleAdd = (produto) => {
    // if product already exists, just increment the quantity
    const productExists = formik.values?.produtos?.find(
      (item) => item?._id === produto?._id
    );

    if (productExists) {
      const produtos = formik.values?.produtos?.map((item) => {
        if (item?._id === produto?._id) {
          return {
            ...item,
            qtdRetirada,
          };
        }
        return item;
      });

      formik.setFieldValue("produtos", produtos);
      setShowAddProduto(false);
      setShowAddProdutoLote(false);
      setRows(produtos);
    } else {
      const produtos = [
        ...(formik.values?.produtos || []),
        {
          _id: produto?._id,
          qtdRecebida: produto?.qtdRecebida,
          produto: produto?.produto,
          numPedido: produto?.numPedido,
          lote: produto?.lote,
          unidadeMedida: produto?.unidadeMedida,
          qtd: produto?.qtd,
          valorTotalSolicitado: produto?.valorTotalSolicitado,
          dataVencimento: produto?.dataVencimento,
          valorRetirado: 0,
        },
      ];
      setShowAddProduto(false);
      setShowAddProdutoLote(false);
      formik.setFieldValue("produtos", produtos);
      setRows(produtos);
    }
  };

  useEffect(() => {
    if (currentItem) {
      handleAdd(currentItem);
      setCurrentItem(null);
    }
  }, [currentItem]);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!isDetalhar && (!isVenda || !process.env.REACT_APP_IS_PRIME_PLUS) && (
          <MDButton
            variant="contained"
            color="info"
            type="button"
            onClick={() => {
              setShowAddProduto(true);
            }}
          >
            Adicionar Item
          </MDButton>
        )}
      </div>

      {rows?.length > 0 && (
        <div style={{ marginTop: "-40px" }}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            disablePagination={true}
            noEndBorder
          />
        </div>
      )}

      {rows?.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 5,
          }}
        >
          Nenhum item adicionado
        </div>
      )}
    </>
  );
}
